import { RequestMethod } from "../../enums/RequestMethod";

export const defaultVersion : number = 1;
export const apiBaseUri = (version?: number) : string =>{
    return `api/v${version || defaultVersion}/`;
};
export const apiToolsBaseUri = (version?: number) : string =>{
    return `api/v${version || defaultVersion}/tools/`
};
export const getUri = (useCase: string) : string =>{
    return process.env.REACT_APP_URL + useCase;
}
export const getApiUri = (useCase: string, params?: any) : URL =>{
    let url = new URL((process.env.REACT_APP_API) + useCase);
    if(params){
        url.search = new URLSearchParams(params).toString();
    }
    return url;
}

// interface CallProps{
//   method: RequestMethod;
//   url: string | URL;
//   token: string;
//   bodyToJson?: object;
//   skipJson?: boolean;
// }

export const call = async (
  method: RequestMethod,
  url: string | URL,
  token?: string,
  bodyToJson?: object,
  skipJson?: boolean,
  bodyRaw?: any,
  respRaw?: boolean,
)  => {
    const headerData : any = {
      "Accept": "application/json",
      // "Content-Type": bodyToJson ? "application/json; charset=utf-8" : undefined,
      Authorization: `Bearer ${token}`
    }
    if(bodyToJson){
      headerData["Content-Type"] = "application/json; charset=utf-8";
    }
    return await fetch(url.toString(), {
        method: method,
        headers: headerData,
        // headers: {
        //   "Accept": "application/json",
        //   // "Content-Type": bodyToJson ? "application/json; charset=utf-8" : undefined,
        //   Authorization: `Bearer ${token}`
        // },
        body: JSON.stringify(bodyToJson) || bodyRaw
      }).then(resp=>{
        if(respRaw){
          return resp
        }else if(resp.ok&&!skipJson){
            return resp?.json();
        }else if(resp.ok){
          return resp;
        }else if(resp.status===403||resp.status===401){
          console.error("bez oprávnění");
          sessionStorage.removeItem("token");
          sessionStorage.setItem("msg", "Vaše relace vypršela, je třeba se opět přihlásit.");
          localStorage.removeItem("kwetoken");
          window.location.href = window.location.toString();
          throw new Error("403")
        }else{
          throw new Error("Chyba!")
        }
      });
}