// import { Link } from 'gatsby';
import React from 'react';
import "../styles/footer.css";
import iconFacebook from "../images/icons/logos/Icon-Facebook.svg";
import iconTwitter from "../images/icons/logos/Icon-Twitter.svg";
import iconLinkedin from "../images/icons/logos/Icon-LinkedIn.svg"
import iconInstagram from "../images/icons/logos/Icon-Instagram.svg"
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Link } from 'gatsby';

const Footer = () => {

    const d = new Date();

    function handleClickEmail(){
        if(typeof window !== undefined){
            window.gtag("event", "click", { send_to: "AW-10886923757/Q1tgCI25zLQDEO2Lpcco"})
        }
    }

    return (
        <>
            <div className="container-fluid section-footer-card">
                <hr/>
                <div className="card">
                    <div className="row container-xl">
                        <div className="col-sm-12 col-md-6">
                            <p className="copyright">© {d.getFullYear() || `2022`} Keyword Eater, s.r.o. Všechna práva vyhrazena.</p>
                            <p className="developed-with-love mb-4 lg:mb-4">Nadesignováno a vyvíjeno v České republice. 🇨🇿</p>
                            <hr className='mb-4' />
                            <p className='company-name mb-2'>Keyword Eater, s.ro.</p>
                            <p className='muted mb-2'>Piletická 486, Věkoše<br/>503 41  Hradec Králové</p>
                            <p className='muted'>Společnost je zapsána v obchodním rejstříku u Krajského soudu v Hradci Králové, oddílu C, vložce 47788.</p>
                            <div className="link-section">
                                <Link to="/terms">Všeobecné obchodní podmínky</Link>
                                <Link to="/privacy-policy">Pravidla ochrany osobních údajů</Link>
                                <Link to="/cookies">Nastavení Cookies</Link>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <a
                                className="mailto" 
                                href="mailto:ahoj@keywordeater.cz"
                                onClick={handleClickEmail}
                            >
                                ahoj@keywordeater.cz
                            </a>
                            <div className="icon-list">
                                <a href="https://www.facebook.com/KeywordEater/" className="icon">
                                    <img src={iconFacebook} alt="Facebook" />
                                </a>
                                <a href="https://www.instagram.com/keywordeater/" className="icon">
                                    <img src={iconInstagram} alt="Instagram" />
                                </a>
                                <a href="https://twitter.com/KeywordEater" className="icon">
                                    <img src={iconTwitter} alt="Twitter" />
                                </a>
                                <a href="https://www.linkedin.com/company/keyword-eater" className="icon">
                                    <img src={iconLinkedin} alt="LinkedIn" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
  }
  export default Footer;