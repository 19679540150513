import React, {useState} from 'react';
import { Link } from 'gatsby'
import "../styles/navbar.css";
import logo from "../images/icons/logo.svg";
import logoMenu from "../images/icons/Menu.svg";
import logoClose from "../images/icons/Close.svg";

const NavBar = () => {

    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    function handleClickEmail(){
        if(typeof window !== undefined){
            window.gtag("event", "click", { send_to: "AW-10886923757/Q1tgCI25zLQDEO2Lpcco"})
        }
    }

    return(
        <nav className="nav-main">
            <div className={`container-xl navbar ${isExpanded && `navbar-expanded`}`}>
                <ul className='navbar-section navbar-section-left'>
                    <li>
                        {/* <OutboundLink href='mailto:ahoj@keywordeater.cz'>ahoj(at)keywordeater.cz</OutboundLink> */}
                        <a 
                            href='mailto:ahoj@keywordeater.cz'
                            onClick={handleClickEmail}
                        >
                            ahoj(at)keywordeater.cz
                        </a>
                    </li>
                    <li>
                        <a href='/#socials'>Socky</a>
                        {/* <a href='https://www.facebook.com/KeywordEater/'>Facebook</a> */}
                    </li>
                </ul>
                <Link className="logo-link" to="/">
                    <img
                        className="logo-desktop"
                        src={logo}
                        alt="Keyword Eater Logo" 
                    />
                    {/* <img
                        className="logo-mobile"
                        src={logoMobile}
                        alt="Keyword Eater Logo" 
                    /> */}
                </Link>
                <ul className='navbar-section'>
                    <li>
                        <Link
                            activeClassName='current'
                            to='/#functions'
                        >
                            Funkce
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName='current'
                            to='/#benefits'
                        >
                            Výhody
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClassName='current'
                            to='/#reference'
                        >
                            Reference
                        </Link>
                    </li>
                </ul>
                <div className='login-button lg:ml-8'>
                    <a
                        href='https://app.keywordeater.cz/'
                        className='btn btn-cta lg:px-4'
                    >
                        Přihlásit se
                    </a>
                </div>
                <button
                    className="expand"
                    onClick={()=>setIsExpanded(!isExpanded)}
                >
                    <img
                        className="logo-mobile"
                        src={logoMenu}
                        alt="hamburger menu" 
                    />
                    <img
                        className="logo-mobile"
                        src={logoClose}
                        alt="hamburger menu close" 
                    />
                </button>
            </div>
        </nav>)
}

export default NavBar;